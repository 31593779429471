/** @format */

import { Card, Page } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useFirebaseContext } from "../../../FirebaseContext";

const InventoryCrawlerStatusSection = () => {
	const { expressFunction } = useFirebaseContext();

	const ticketMasterInventoryCrawlerStatus = expressFunction(
		"ticketMasterInventoryCrawlerStatus"
	);

	const resetFailedEvents = expressFunction("resetFailedEvents");
	const resetAllEventsInventory = expressFunction("resetAllEventsInventory");

	const [crawlerStatus, setCrawlerStatus] = useState({});
	const [blockAllOtherRequests, setBlockAllOtherRequests] = useState(false);

	useEffect(() => {
		console.log("crawlerStatus", crawlerStatus);
		ticketMasterInventoryCrawlerStatus({}).then((response) => {
			const { data } = response;

			setCrawlerStatus(data);
			// console.log("data", data);
		});
	}, []);

	const [resetFailedEventsRequested, setResetFailedEventsRequested] =
		useState(false);
	const performResetFailedEvents = () => {
		setResetFailedEventsRequested(true);
		resetFailedEvents({}).then((response) => {
			window.location.reload();
		});
	};

	return (
		<Card
			title="Inventory Crawler Status"
			sectioned
			actions={[
				{
					content: "Reset Failed Events",
					disabled: blockAllOtherRequests,
					onAction: () => {
						setBlockAllOtherRequests(true);
						performResetFailedEvents();
					},
				},
				{
					content: "Reset All Events Inventory",
					disabled: blockAllOtherRequests,
					onAction: () => {
						setBlockAllOtherRequests(true);
						resetAllEventsInventory({}).then((response) => {
							window.location.reload();
						});
					},
				},
			]}
		>
			{crawlerStatus.data && (
				<>
					<p>
						<b>Number of Events Being Watched</b>:{" "}
						{crawlerStatus.data.numberEventsBeingWatched}
					</p>
					<p>
						<b>Number of Events Waiting</b>:{" "}
						{crawlerStatus.data.numberOfEventsWaitingForINVFetch}
					</p>
					<p>
						<b>Number of Events That Failed</b>:{" "}
						{crawlerStatus.data.numberOfEventsThatFailed}
					</p>
					<hr />
					<p>
						<b>Number of Events Per 15 Minutes</b>:{" "}
						{crawlerStatus.data.numberOfEventsPer15min}
					</p>
					<p>
						<b>Number of Events Per 30 Minutes</b>:{" "}
						{crawlerStatus.data.numberOfEventsPer30min}
					</p>
					<p>
						<b>Number of Events Per 60 Minutes</b>:{" "}
						{crawlerStatus.data.numberOfEventsPer60min}
					</p>
					<sub>*** This is successful events. Not attempts.</sub>
				</>
			)}
		</Card>
	);
};

const LatestCrawledEventsSection = () => {
	const { expressFunction } = useFirebaseContext();
	const [latestEvents, setLatestEvents] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [showHelp, setShowHelp] = useState(false);

	const fetchLatestCrawledEvents = expressFunction("latestCrawledEvents");

	useEffect(() => {
		setIsLoading(true);
		fetchLatestCrawledEvents({}).then((response) => {
			const data = response.data.data;
			if (data && Array.isArray(data)) {
				setLatestEvents(data);
			}
			setIsLoading(false);
		}).catch(error => {
			console.error("Error fetching latest crawled events:", error);
			setIsLoading(false);
		});
	}, []);

	// Format the date for display in YYYY-MM-DD format (UTC)
	const formatDate = (dateString) => {
		if (!dateString) return "N/A";
		const date = new Date(dateString);
		const year = date.getUTCFullYear();
		const month = String(date.getUTCMonth() + 1).padStart(2, "0");
		const day = String(date.getUTCDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	};

	// Format number with commas for thousands
	const formatNumber = (num) => {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	return (
		<Card title='Latest Crawled Events' sectioned >
			<div style={{ textAlign: "right", marginTop: "-30px", marginBottom: "10px" }}>
				<span 
					onClick={() => setShowHelp(!showHelp)} 
					style={{ 
						opacity: 0.4, 
						cursor: "pointer",
						fontSize: "16px",
						fontWeight: "bold",
						width: "20px",
						height: "20px",
						borderRadius: "50%",
						border: "1px solid currentColor",
						display: "inline-flex",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					?
				</span>
			</div>


			{showHelp && (
				<div style={{ marginBottom: "16px", padding: "12px", backgroundColor: "#f5f5f5", borderRadius: "4px" }}>
					<p style={{ marginBottom: "8px" }}>This report shows for each date, the number of events for which the latest crawled inventory data falls on that day. For example, if an event has not been crawled at all in the past 3 days, it will contribute a "+1" count to the date that's 3 days ago. Ideally, almost all events should fall on "today" with a very small and steady number of events falling out because they are in the past or otherwise legitimately went offline.</p>
					<button 
						onClick={() => setShowHelp(false)}
						style={{
							padding: "4px 8px",
							backgroundColor: "#e0e0e0",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer"
						}}
					>
						Hide help
					</button>
				</div>
			)}
			{isLoading ? (
				<p>Loading latest crawled events data...</p>
			) : latestEvents.length === 0 ? (
				<p>No data available</p>
			) : (
				<table style={{ borderCollapse: "collapse", width: "auto" }}>
					<thead>
						<tr>
							<th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd" }}>Date</th>
							<th style={{ textAlign: "right", padding: "8px", borderBottom: "1px solid #ddd" }}>Event Count</th>
						</tr>
					</thead>
					<tbody>
						{latestEvents.map((event, index) => (
							<tr key={index}>
								<td style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd" }}>
									{formatDate(event.day_bucket)}
								</td>
								<td style={{ textAlign: "right", padding: "8px", borderBottom: "1px solid #ddd" }}>
									{formatNumber(event.event_count)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</Card>
	);
};

const HourlyLatestCrawledEventsSection = () => {
	const { expressFunction } = useFirebaseContext();
	const [hourlyEvents, setHourlyEvents] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const fetchHourlyLatestCrawledEvents = expressFunction("hourlyLatestCrawledEvents");

	useEffect(() => {
		setIsLoading(true);
		fetchHourlyLatestCrawledEvents({}).then((response) => {
			const data = response.data.data;
			if (data && Array.isArray(data)) {
				setHourlyEvents(data);
			}
			setIsLoading(false);
		}).catch(error => {
			console.error("Error fetching hourly latest crawled events:", error);
			setIsLoading(false);
		});
	}, []);

	// Format the date and time for display in YYYY-MM-DD HH:00 format (UTC)
	const formatDateTime = (dateString) => {
		if (!dateString) return "N/A";
		const date = new Date(dateString);
		const year = date.getUTCFullYear();
		const month = String(date.getUTCMonth() + 1).padStart(2, "0");
		const day = String(date.getUTCDate()).padStart(2, "0");
		const hour = String(date.getUTCHours()).padStart(2, "0");
		return `${year}-${month}-${day} ${hour}:00 UTC`;
	};

	// Format number with commas for thousands
	const formatNumber = (num) => {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	return (
		<Card title="Hourly Latest Crawled Events (Last 24 Hours)" sectioned>
			{isLoading ? (
				<p>Loading hourly latest crawled events data...</p>
			) : hourlyEvents.length === 0 ? (
				<p>No data available</p>
			) : (
				<table style={{ borderCollapse: "collapse", width: "auto" }}>
					<thead>
						<tr>
							<th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd" }}>Hour</th>
							<th style={{ textAlign: "right", padding: "8px", borderBottom: "1px solid #ddd" }}>Event Count</th>
						</tr>
					</thead>
					<tbody>
						{hourlyEvents.map((event, index) => (
							<tr key={index}>
								<td style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd" }}>
									{formatDateTime(event.hour_bucket)}
								</td>
								<td style={{ textAlign: "right", padding: "8px", borderBottom: "1px solid #ddd" }}>
									{formatNumber(event.event_count)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</Card>
	);
};

const CrawlerStatusPage = () => {
	return (
		<Page
			title="Crawler Status Page"
			subtitle="This is the Crawler Status Page"
			fullWidth
		>
			<InventoryCrawlerStatusSection />
			<br />
			<LatestCrawledEventsSection />
			<br />
			<HourlyLatestCrawledEventsSection />
		</Page>
	);
};

export default CrawlerStatusPage;
